body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4a4a4a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.limited-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
}

a {
  color: #007bff !important;
}



.topHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  border-bottom: 2px solid var(--black, #000000);
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.groupBtn a {
  font-family: var(--headlinefont);
  background-color: var(--white);
  color: var(--black) !important;
  text-decoration: none;
  padding: 15px 40px;
  font-weight: 700;
  border-color: var(--black);
  border-width: 3px;
  border-style: solid;
  letter-spacing: 0;
  border-radius: 50px;
  text-transform: none;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .4);
  /* border-radius: 50px;
  box-shadow: none;
  padding: 11px 60px;
  margin-left: 20px;
  border: 2px solid var(--black, #0D0D0D);
  color: var(--black, #0D0D0D); */
}

.groupBtn a:hover {
  box-shadow: 0 8px 5px -5px rgba(0, 0, 0, .25) !important;
  transform: translateY(-10px) !important;
}

.groupBtn a.black {
  font-family: var(--headlinefont);
  background-color: var(--black, #0D0D0D);
  color: var(--white, #ffffff) !important;
  text-decoration: none;
  padding: 15px 20px;
  font-weight: 700;
  border-color: var(--transparent);
  border-width: 0;
  border-style: solid;
  letter-spacing: 0;
  text-transform: none;
  text-shadow: 0 0 0 transparent;
  margin-right: 30px;
  /* border: 2px solid var(--black, #0D0D0D);
  background: var(--black, #0D0D0D);
  color: var(--white, #ffffff); */
}

.topHeader .logo img {
  width: 250px;
}