.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.modal-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}
.modal-content {
  width: 600px;
  text-align: center;
  flex-shrink: 0;
  background-color: white;
  padding: 80px 40px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 90%;
  z-index: 1001;
  border-radius: 15px;
  border: 2px solid var(--black, #0d0d0d);
  background: #fff;
}

.close-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.modal-overlay {
  animation: modalFadeIn 0.5s;
}

.modalText {
  color: #000;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modalInputs {
  border-radius: 5px;
  border: 2px solid #000;
  background: #fff;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.modalBtn {
  display: flex;
  padding: 11px 25px;
  margin-top: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-shrink: 0;
  border-radius: 50px;
  border: 2px solid var(--black, #0d0d0d);
  background: var(--black, #0d0d0d);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  color: var(--white, #ffffff);
}
